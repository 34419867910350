import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  useCheckNameMutation,
  useCreateResourceMutation,
  useGetAllFunctionsQuery,
  useGetResourceDetailsQuery,
  useGetResourcesQuery,
} from "../../store/slices/api/assetManagementSlice";
import {
  selectPageInfo,
  setIsActionButtonDisabled,
  setIsLoadingAction,
} from "../../store/slices/appSlice";
import AssetForm from "../../components/assets/asset-form/AssetForm";
import { HomePagePadding } from "../../components/styles/general/General.styles";
import { selectUser } from "../../store/slices/authSlice";
import {
  getTranslation,
  permissions,
  RESOURCE_CATEGORIES,
  showValidationError,
  transitionDirections,
} from "../../util/utils";
import {
  FORM_MODE,
  PRESERVE_FORM_STATE_URLS,
  checkResourceChanged,
  transformResourceInputRequest,
  transformResourceResponse,
  validateResourceForm,
} from "../../util/asset-utils";
import {
  resetResourceInput,
  selectError,
  selectIsFirstSubmitted,
  selectOriginalInput,
  selectResourceInput,
  setIsFirstSubmitted,
  setOriginalInput,
  setResourceInput,
} from "../../store/slices/resourceInputSlice";
import { messageError, messageSuccess } from "../../util/notification";
import PageTransition from "../../components/PageTransition";
import {
  clearList,
  selectResourceImages,
  setList,
} from "../../store/slices/resourceImageSlice";
import {
  clearAttachmentList,
  selectResourceAttachments,
  setAttachmentList,
} from "../../store/slices/resourceAttachmentSlice";
import {
  useGetResourceAttachmentsQuery,
  useUploadResourceAttachmentMutation,
} from "../../store/slices/api/resourceAttachmentsApiSlice";
import ConfirmAlert from "../../store/confirm/ConfirmAlert";
import {
  selectAllTags,
  setGlobalTags,
  setInitialTags,
  setTags,
} from "../../store/slices/tagsSlice";
import {
  useGetResourceTagsQuery,
  useGetTagsQuery,
  usePatchResourceTagsMutation,
} from "../../store/slices/api/tagsSlice";
import EventBus, { EVENT_DISCARD_CHANGES } from "../../util/EventBus";
import Access from "../../components/common/Access";
import ErrorHandling from "../../components/common/ErrorHandling";
import AppAccess from "../../components/common/AppAccess";
import Layer2Access from "../../components/common/Layer2Access";
import {
  useGetResourceImagesQuery,
  useUploadResourceImageMutation,
} from "../../store/slices/api/resourceImagesApiSlice";
import DuplicateAssetHeader from "../../navigation/header/assets/DuplicateAssetHeader";
import { BACKEND_URL } from "../../Constants";
import KeycloakService from "../../services/KeycloakService";

const DuplicateAsset = () => {
  // General Hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { resourceid } = useParams();
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();

  // Selectors
  const user = useSelector(selectUser);
  const organizationId = user?.organizations?.find((o) => o.default)?.id;
  const resourceInput = useSelector(selectResourceInput);
  const originalInput = useSelector(selectOriginalInput);
  const error = useSelector(selectError);
  const isFirstSubmitted = useSelector(selectIsFirstSubmitted);
  const localResourceImages = useSelector(selectResourceImages);
  const localResourceAttachments = useSelector(selectResourceAttachments);
  const tagsRedux = useSelector(selectAllTags);
  const { isLoadingAction } = useSelector(selectPageInfo);

  // States
  const [openConfirm, setOpenConfirm] = useState(false);
  const [redirectRoute, setRedirectRoute] = useState("");

  // Mutations
  const [createResource, { isLoading: isLoadingCreate }] =
    useCreateResourceMutation();

  const [checkName, { isLoading: isLoadingCheckName }] = useCheckNameMutation();

  const [uploadResourceImage, { isLoading: isLoadingImage }] =
    useUploadResourceImageMutation();

  const [uploadResourceAttachment, { isLoading: isLoadingAttachment }] =
    useUploadResourceAttachmentMutation();

  const [patchResourceTags] = usePatchResourceTagsMutation();

  // Queries
  const {
    data: resourceData,
    isLoading: isLoadingResourceData,
    isError: isErrorResourceData,
    error: resourceDataError,
  } = useGetResourceDetailsQuery({
    resourceid,
    organizationId,
  });

  const {
    data: resourceAttachmentsData,
    isLoading: isLoadingAttachments,
    isError: isErrorAttachments,
  } = useGetResourceAttachmentsQuery({
    resourceid,
    organizationId,
  });

  const {
    data: tags,
    isLoading: isLoadingTags,
    isError: isErrorTags,
  } = useGetTagsQuery({
    organizationId,
  });

  const {
    data: resourceTags,
    isLoading: isLoadingResourceTags,
    isError: isErrorResourceTags,
  } = useGetResourceTagsQuery({
    resourceid,
    organizationId: user?.organizations?.find((o) => o.default).id,
  });

  const {
    data: allFunctionsData,
    isLoading: isLoadingFunctions,
    isError: isErrorFunctions,
  } = useGetAllFunctionsQuery({
    organizationId,
  });

  const { data: resourceImagesData, isLoading: isLoadingResourceImages } =
    useGetResourceImagesQuery(
      { resourceid, organizationId },
      { skip: !resourceid }
    );

  const {
    data: rootResourcesData,
    isLoading: isLoadingRootResources,
    isError: isErrorRootResources,
  } = useGetResourcesQuery(
    {
      organizationId,
    },
    { size: 1, index: 1 }
  );

  // Other variables
  const direction = searchParams.get("direction");
  const previousPage = searchParams.get("previousPage");

  // Handlers
  const resetFormState = () => {
    dispatch(resetResourceInput());
    dispatch(setIsActionButtonDisabled(false));
    dispatch(clearList());
    dispatch(clearAttachmentList());
    dispatch(setIsLoadingAction(false));
  };

  const cancelHandler = (redirectUrl) => {
    if (redirectUrl) {
      navigate(redirectUrl);
    } else {
      navigate(redirectRoute);
    }

    // Reset form
    resetFormState();
  };

  const getFunction = (id) => {
    return allFunctionsData?.find((f) => f.id === id);
  };

  const handleCancelAction = () => {
    const backRoute = `/resources/${resourceid}?direction=${transitionDirections.LEFT_TO_RIGHT}`;

    if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
      setRedirectRoute(backRoute);
      setOpenConfirm(true);
      return;
    }

    cancelHandler(backRoute);
  };

  const handleSubmit = async () => {
    dispatch(setIsFirstSubmitted(true));
    const parent = resourceInput.parent;
    const parentFunction = allFunctionsData.find(
      (f) => f.id === parent?.functionId
    );

    const isParentHardwareAsset =
      parentFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET;
    const isParentRack = parentFunction?.category === RESOURCE_CATEGORIES.RACK;
    const hasLocations = rootResourcesData?.totalRecord > 0;

    if (
      resourceInput.category === RESOURCE_CATEGORIES.LOCATION &&
      resourceInput.parentId &&
      (isParentHardwareAsset || isParentRack)
    ) {
      messageError(getTranslation("CHANGE_PARENT_VALIDATION", t, i18n));
      return;
    }

    if (
      resourceInput.category === RESOURCE_CATEGORIES.RACK &&
      resourceInput.parentId &&
      isParentHardwareAsset
    ) {
      messageError(
        getTranslation("CHANGE_HARDWARE_PARENT_VALIDATION", t, i18n)
      );
      return;
    }

    if (
      (resourceInput.category === "HARDWARE_ASSET" ||
        resourceInput.category === "RACK") &&
      !hasLocations
    ) {
      messageError(getTranslation("NO_LOCATIONS_VALIDATION", t, i18n));
      return;
    }

    const { error: evaluatedError, firstError } = validateResourceForm(
      resourceInput,
      t
    );

    const isValid =
      Object.keys(evaluatedError).length === 0 && !Boolean(error.name);
    if (!isValid) {
      if (firstError) {
        messageError(firstError);
      }

      if (error.name) {
        messageError(error.name);
      }
      dispatch(setIsActionButtonDisabled(true));
      return;
    }
    const resourceInputRequest = transformResourceInputRequest(
      resourceInput,
      user?.region,
      true
    );

    try {
      const resource = await createResource({
        resourceInput: resourceInputRequest,
        organizationId: organizationId,
        category: resourceInput.category.replace("_", "-").toLowerCase(),
      }).unwrap();

      if (!resource) return;

      for (let i = 0; i < localResourceImages.length; i++) {
        const resourceImage = localResourceImages[i];

        const {
          imageCategory,
          selectedFile: originalFile,
          file,
          name,
          description,
          isFromDb,
        } = resourceImage;

        let resultFile = file;

        if (isFromDb) {
          const imageResponse = await fetch(BACKEND_URL + originalFile, {
            headers: {
              authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          });

          resultFile = await imageResponse.blob();
        }

        const formData = new FormData();

        if (resultFile) {
          formData.append("file", resultFile);
          formData.append("fileName", name);
          formData.append("imageCategory", imageCategory);

          if (description) {
            formData.append("description", description);
          }
        }

        await uploadResourceImage({
          formData,
          resourceid: resource.id,
          organizationId,
        }).unwrap();
      }

      for (let i = 0; i < localResourceAttachments.length; i++) {
        const resourceAttachment = localResourceAttachments[i];
        const {
          selectedFile: originalFile,
          file,
          name,
          description,
          isFromDb,
        } = resourceAttachment;

        let resultFile = file;
        if (isFromDb) {
          const attachmentResponse = await fetch(BACKEND_URL + originalFile, {
            headers: {
              authorization: `Bearer ${KeycloakService.getToken()}`,
            },
          });

          resultFile = await attachmentResponse.blob();
        }

        const formData = new FormData();
        if (resultFile) {
          formData.append("file", resultFile);
          formData.append("fileName", name);
          formData.append("description", description);
        }

        await uploadResourceAttachment({
          formData,
          resourceid: resource.id,
          organizationId,
        });
      }

      // Create tags for the resource
      // For add, send tags where 'new' = true AND 'deleted' = false
      const tagsToBeAdded = tagsRedux
        .filter((t) => t.new && !t.deleted)
        .map((t) => {
          return {
            displayName: t.name,
            color: t.color,
          };
        });
      // For edit, send tags where 'new' = false AND 'deleted' = false
      const tagsToBeEdited = tagsRedux
        .filter((t) => !t.new && !t.deleted)
        .map((t) => {
          return {
            id: t.id,
            displayName: t.name,
            color: t.color,
          };
        });
      // For delete, send tags where 'new' = false AND 'deleted' = true
      // const tagsToBeDeleted = tags.filter((t) => !t.new && t.deleted);
      const resourceFunction = getFunction(resourceData?.functionId);
      const resourceCategory = `${
        resourceFunction?.category === RESOURCE_CATEGORIES.HARDWARE_ASSET
          ? "hardware-asset"
          : resourceFunction?.category.toLowerCase()
      }s`;
      const tagsPayload = [...tagsToBeAdded, ...tagsToBeEdited];
      await patchResourceTags({
        resourceid: resource?.id,
        organizationId: user?.organizations?.find((o) => o.default).id,
        resourceCategory,
        tags: tagsPayload,
      }).unwrap();

      messageSuccess(getTranslation("ASSET_DUPLICATED_SUCCESSFULLY", t, i18n));
      navigate(`/resources/${resource.id}/details`);

      // Reset Form
      resetFormState();
    } catch (error) {
      dispatch(setIsLoadingAction(false));
      showValidationError(error, t, i18n);
      console.error("Failed to update a resource", error);
    }
  };

  // Other variables
  const resourceFunction = getFunction(resourceData?.functionId);

  const permissionsKey = `ASSET_MANAGEMENT_${
    resourceFunction?.category === "HARDWARE_ASSET"
      ? "HARDWARE"
      : resourceFunction?.category
  }_ADD`;

  // Effects
  useEffect(() => {
    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);
    if (!preserve) {
      // Reset form and validations
      resetFormState();
    }
  }, []);

  useEffect(() => {
    const fetchResourceData = async (data) => {
      const checkData = await checkName({
        name: data.name,
        organizationId,
      }).unwrap();

      const resource = transformResourceResponse({
        ...data,
        name: checkData?.suggestion,
        function: getFunction(data.functionId),
      });

      //Load data into resource input
      dispatch(setResourceInput(resource));
      dispatch(setOriginalInput(resource));
    };

    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);
    if (!preserve && resourceData) {
      fetchResourceData(resourceData);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceData]);

  useEffect(() => {
    const handleNavigation = (data) => {
      if (checkResourceChanged(originalInput, resourceInput, user?.region)) {
        setRedirectRoute(data.route);
        setOpenConfirm(true);
      } else {
        cancelHandler(data.route);
      }
    };

    EventBus.on(EVENT_DISCARD_CHANGES, handleNavigation);
    return () => EventBus.remove(EVENT_DISCARD_CHANGES, handleNavigation);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput, originalInput]);

  useEffect(() => {
    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);
    if (!preserve && resourceImagesData) {
      let newList = resourceImagesData?.slice().map((resourceImage) => {
        const item = {
          imageCategory: resourceImage.imageCategory,
          selectedFile: resourceImage.uri,
          resourceid,
          id: resourceImage.id,
          name: resourceImage.name,
          mimeType: resourceImage.name?.substring(
            resourceImage.name?.lastIndexOf(".")
          ),
          description: resourceImage.description,
          file: null,
          isFromDb: true,
        };

        return item;
      });

      dispatch(setList(newList));
    }
  }, [resourceImagesData]);

  useEffect(() => {
    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);

    if (!preserve && resourceAttachmentsData) {
      let newList = resourceAttachmentsData
        ?.slice()
        .map((resourceAttachment) => {
          const item = {
            selectedFile: resourceAttachment.uri,
            resourceid,
            id: resourceAttachment.id,
            name: resourceAttachment.name,
            description: resourceAttachment.description,
            mimeType: resourceAttachment.name.substring(
              resourceAttachment.name.lastIndexOf(".")
            ),
            file: null,
            isFromDb: true,
          };

          return item;
        });

      dispatch(setAttachmentList(newList));
    }
  }, [resourceAttachmentsData]);

  // Here, we will fetch the tags and put them into the Redux store (similar to resource images and attachments)
  useEffect(() => {
    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);

    if (!preserve && tags) {
      const mappedTags = tags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setGlobalTags(mappedTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags]);

  useEffect(() => {
    const preserve = PRESERVE_FORM_STATE_URLS.includes(previousPage);

    if (!preserve && resourceTags) {
      const mappedResourceTags = resourceTags.map((tag) => {
        return {
          id: tag.id,
          name: tag.displayName,
          color: tag.color,
          lastAssignment: tag.lastAssignment,
          new: false,
          edited: false,
          deleted: false,
          isFromDb: true,
        };
      });

      dispatch(setInitialTags(mappedResourceTags));
      dispatch(setTags(mappedResourceTags));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceTags]);

  useEffect(() => {
    if (!isFirstSubmitted) return;

    const { error: evaluatedError } = validateResourceForm(resourceInput, t);
    const isValid = Object.keys(evaluatedError).length === 0 && !error.name;

    if (isValid) {
      dispatch(setIsActionButtonDisabled(false));
    } else {
      dispatch(setIsActionButtonDisabled(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceInput]);

  useEffect(() => {
    if (isLoadingCreate || isLoadingAttachment || isLoadingImage) {
      dispatch(setIsLoadingAction(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingCreate, isLoadingAttachment, isLoadingImage]);

  if (resourceDataError?.status === 404) {
    return <Navigate to="*" />;
  }

  return (
    <AppAccess>
      <ErrorHandling
        isLoading={
          isLoadingAttachments ||
          isLoadingFunctions ||
          isLoadingResourceData ||
          isLoadingCheckName ||
          isLoadingResourceTags ||
          isLoadingRootResources ||
          isLoadingTags ||
          isLoadingCreate ||
          isLoadingResourceImages ||
          isLoadingAction
        }
        isError={
          isErrorAttachments ||
          isErrorFunctions ||
          isErrorResourceData ||
          isErrorResourceTags ||
          isErrorRootResources ||
          isErrorTags
        }
      >
        <Access all={[permissions[permissionsKey]]}>
          <Layer2Access>
            <PageTransition direction={direction}>
              <ConfirmAlert
                isOpen={openConfirm}
                setIsOpen={setOpenConfirm}
                alert={{
                  content: getTranslation("CANCEL_ALERT_CONTENT", t, i18n),
                  confirmTitle: getTranslation("DISCARD_CHANGES", t, i18n),
                  closeTitle: getTranslation("CANCEL", t, i18n),
                  showConfirm: true,
                }}
                handleConfirm={cancelHandler}
                label="discard-changes"
              />
              <DuplicateAssetHeader
                handleAction={handleSubmit}
                handleCancelAction={handleCancelAction}
              />
              <HomePagePadding>
                <AssetForm
                  id={resourceid}
                  setOpenConfirm={setOpenConfirm}
                  setRedirectRoute={setRedirectRoute}
                  allFunctionsData={allFunctionsData}
                  mode={FORM_MODE.DUPLICATE}
                />
              </HomePagePadding>
            </PageTransition>
          </Layer2Access>
        </Access>
      </ErrorHandling>
    </AppAccess>
  );
};

export default DuplicateAsset;
